import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { useThemeSettings } from '../../hooks/use-theme-settings';
import { FooterMenu } from './Menu';

export const Footer = () => {
    const { email } = useThemeSettings();

    const year = new Date().getFullYear();

    return (
        <StyledFooterBlock className="footer">
            <Container>
                <Row>
                    <Col md="6" className="footer__contacts"></Col>
                    <Col md="6" className="footer__nav">
                        <h2>GoPrestamo</h2>
                        <FooterMenu />
                    </Col>
                </Row>
                <div className="footer__copyright">&copy;&nbsp;{year}&nbsp;GoPrestamo.es</div>
            </Container>
        </StyledFooterBlock>
    );
};

const StyledFooterBlock = styled.footer`
    background: #f4f4f4;
    padding-bottom: 40px;
    padding-top: 30px;

    @media (min-width: 768px) {
        padding-top: 70px;
    }

    h2 {
        color: #4c4c4c;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;

        @media (min-width: 768px) {
            font-size: 18px;
        }
    }

    a {
        color: rgba(76, 76, 76, 0.64);
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.15s ease;

        &:hover,
        &:focus {
            color: rgba(76, 76, 76, 0.8);
        }
    }

    .footer__nav {
        margin-top: 50px;
        @media (min-width: 768px) {
            margin-top: 0;
            text-align: right;
        }
    }

    .footer__copyright {
        border-top: 1px solid #d3d2d2;
        color: rgba(76, 76, 76, 0.8);
        font-size: 16px;
        margin-top: 50px;
        padding-top: 30px;

        @media (min-width: 768px) {
            padding-top: 40px;
            position: relative;
            text-align: center;
        }

        &::before {
            @media (min-width: 768px) {
                background: #f4f4f4;
                content: '';
                height: 1px;
                left: 50%;
                position: absolute;
                top: -1px;
                transform: translateX(-50%);
                width: 27%;
            }
        }
    }
`;
