import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const NotFound = () => {
    return (
        <StyledNotFoundBlock>
            <Container>
                <h1 dangerouslySetInnerHTML={{ __html: '404' }} />
                <span dangerouslySetInnerHTML={{ __html: 'Página no encontrada' }} />
            </Container>
        </StyledNotFoundBlock>
    );
};

const StyledNotFoundBlock = styled.div`
    text-align: center;
    padding: 50px 0;
    h1 {
        margin: 0;
        color: #378aff;
        font-size: 140px;
        font-weight: 700;

        @media (min-width: 768px) {
            font-size: 200px;
        }
    }
    span {
        color: #378aff;
        font-size: 18px;
        font-weight: 400;

        @media (min-width: 768px) {
            font-size: 35px;
        }
    }
`;
