import { useStaticQuery, graphql } from 'gatsby';

export const useMainMenu = () => {
    const { wpMenu } = useStaticQuery(
        graphql`
            query MainMenu {
                wpMenu(databaseId: { eq: 3 }) {
                    menuItems {
                        nodes {
                            databaseId
                            path
                            label
                        }
                    }
                }
            }
        `
    );

    return wpMenu.menuItems.nodes;
};
