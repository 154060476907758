export const SETTINGS = {
    URL_MASTER: 'https://qa-delivery-goprestamo-es-master-wp.moneyman.ru/wp',
};

export const API = {
    SEND_FORM: '/rest/sendForm.php',
    HEADERS: {
        POST: {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    },
};

export const FORM = {
    FORM_INCORRECT: 'Проверьте правильность заполненных данных.',
    FORM_SUCCESS: 'Спасибо! Мы свяжемся с Вами в ближайшее время.',
    FORM_ERROR: 'Возникла ошибка, попробуйте позже.',
};

export const AMOUNT_OPTIONS = [
    { value: 200, label: '200' },
    { value: 300, label: '300' },
    { value: 400, label: '400' },
    { value: 1000, label: '1000' },
];

export const TERM_OPTIONS = [
    { value: 91, label: '91' },
    { value: 120, label: '120' },
];
