import { useStaticQuery, graphql } from 'gatsby';

export const useThemeSettings = () => {
    const { wp } = useStaticQuery(
        graphql`
            query ThemeSettings {
                wp {
                    acfOptionsFooter {
                        contacts {
                            email
                        }
                    }
                }
            }
        `
    );

    return wp.acfOptionsFooter.contacts;
};
