import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/index.css';

import { HelmetBlock } from '../components/HelmetBlock';
import { Header } from '../components/HeaderBlock';
import { Footer } from '../components/FooterBlock';
import { NotFound } from '../components/NotFoundBlock';
import styled from 'styled-components';

export default () => {
    const seoData = {
        title: '404',
        databaseId: null,
        seo: {
            title: 'Página no encontrada',
        },
    };

    return (
        <StyledPageWrapperBlock>
            <HelmetBlock data={seoData} />
            <Header data={seoData} />
            <NotFound />
            <Footer />
        </StyledPageWrapperBlock>
    );
};

const StyledPageWrapperBlock = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
