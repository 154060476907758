import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { useMainMenu } from '../../../hooks/use-main-menu';
import { SETTINGS } from '../../../settings';

export const FooterMenu = () => {
    const items = useMainMenu();

    return (
        <StyledMenu>
            {items &&
                items.map((item) => (
                    <li key={item.databaseId}>
                        <Link to={item.path.replace('wp/', '')} dangerouslySetInnerHTML={{ __html: item.label }} />
                    </li>
                ))}
        </StyledMenu>
    );
};

const StyledMenu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    li + li {
        margin-top: 12px;
    }
`;
